/*  default
    ========================================================================== */
    .card {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        filter: $card-box_shadow;
        @extend .border-0;
      .card-body {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        .card-caption {
          .card-title-link {
            @extend .m-0;
          }
          .card-title {
            @extend .tt-card-title;
          }
          .card-subtitle {
            @extend .tt-card-subtitle;
          }
          .card-description {
			min-height: auto;
			max-height: none;
            &::after {
              @extend .d-none;
            }
          }
        }

      }
      &.video {

        * {
          transition: $transition-base;
        }
        .card-image {
          background-color: $primary;
          &::after {
            opacity: 0;
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            pointer-events: none;
            @extend .d-flex;
            @extend .justify-content-center;
            @extend .align-items-center;
            background-image: url('~project/public/images/play.png');
            background-position: center center;
            background-size: 15px;
            background-repeat: no-repeat;
            transition: $transition-base;
          }
        }

        &:hover {
          .card-image {
            img {
              filter: brightness(75%);
            }

            &::after {
              opacity: 1;
              background-size: 75px;
            }
          }
        }
      }
    }
