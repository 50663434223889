&.landing_page {
  .white-block {
    .container-default {
      .container-holder {
        max-width: 620px;
        @extend .mx-auto;
        @extend .text-center;
        margin-bottom: 15px;
      }
    }
  }
  .block-section {
    @extend .white-block;
    overflow: hidden;
    padding: 0 !important;
    &.block-left {
      .container-two-columns {
        .column.one {
          @extend .p-5;
          @extend .pt-lg-5;
          @extend .pl-lg-5;

          @include make-col-ready();
          @include make-col(12);

          @include media-breakpoint-up(lg) {
            @include make-col(4);
          }
        }
        .column.two {
          @include make-col-ready();
          @include make-col(12);
          @extend .p-0;
          padding-right: 15px !important;

          @include media-breakpoint-up(lg) {
            @include make-col(8);
          }
          @include media-breakpoint-down(md) {
            padding: 0 15px !important;
          }
          @include media-breakpoint-down(xs) {
            padding: 0 !important;
          }
        }
      }
    }
    &.block-right {
      .container-two-columns {
        .column.one {
          @include make-col-ready();
          @include make-col(12);
          @extend .p-0;
          padding-left: 15px !important;

          @include media-breakpoint-up(lg) {
            @include make-col(8);
          }
          @include media-breakpoint-down(md) {
            padding: 0 15px !important;
          }
          @include media-breakpoint-down(xs) {
            padding: 0 !important;
          }
        }
        .column.two {
          @extend .p-5;
          @extend .pt-lg-5;
          @extend .pr-lg-5;
          @extend .pl-lg-5;

          @include make-col-ready();
          @include make-col(12);

          @include media-breakpoint-up(lg) {
            @include make-col(4);
          }
        }
      }
    }
    // .owl-carousel {
    //   min-height: 400px;
    //   @include media-breakpoint-up(md) {
    //     min-height: 40vh;
    //   }
    //   .item {
    //     min-height: 400px;
    //     @include media-breakpoint-up(md) {
    //       min-height: 40vh;
    //     }
    //   }
    // }
  }
  .content-blocks {
    margin-top: -50px;
  }
  .banner {
    padding-top: 0;
    padding-bottom: 80px;
    .owl-carousel {
      .item {
        .owl-caption {
          @include media-breakpoint-up(lg) {
            margin-right: 30px !important;
          }
        }
      }
    }
  }
  .videos {
    padding-top: 0 !important;
  }
  .videos,
  .news-blocks {
    // padding-bottom: 150px;
    .container-holder {
      position: relative;
      .btn-more {
        @include media-breakpoint-up(md) {
          position: absolute;
          right: -50px;
          top: 36%;
        }
      }
    }
  }
}

.blog_detail {
  @include make-row();

  .info {
    @include make-col-ready();
    @include make-col(12);

    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }

    @extend .mb-4;
    @extend .pr-lg-5;
  }

  .info-extra {
    @include make-col-ready();
    @include make-col(12);

    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }

    @extend .mb-4;
    @extend .pl-lg-5;
  }
}
