.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: url('/images/header-dashed-bg.png') repeat-x bottom $green;
	transition: 0.5s;

	.container,
	.container-fluid {
		.container-holder {
			.column {
				display: flex;
				align-items: center;
				min-height: 100px;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// navbar-toggler
	.navbar-toggler {
		display: flex;
		align-items: center;
		margin: 34px 0 34px auto;
		padding: 5px 10px;
		background: $pink;
		border-radius: 6px;
		border: 1px dashed $white;
		box-shadow: 0 0 0 4px $pink;
		color: $white;
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
		transition: 0.5s;

		.navbar-toggler-icon {
			width: unset !important;
			height: unset !important;
		}

		i {
			margin-right: 5px;
			font-weight: 400;
			text-align: center;
		}

		&[aria-expanded="true"] {
			i {
				&::before {
					content: "\f00d";
				}
			}
		}

		&:hover {
			background-color: $blue-dark;
			box-shadow: 0 0 0 4px $blue-dark;
		}
	}

	// logo
	.logo {
		position: absolute;
		top: 15px;
		left: 15px;
		width: 200px;
		transition: 0.5s;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 3;

			.navbar-nav {
				margin-bottom: 15px;

				>.nav-item {
					border-top: 1px dashed rgba($white, 0.5);

					>.nav-link {
						display: flex;
						align-items: center;
						padding: 0.75rem 0;
						color: $white;
						font-size: 16px;

						&:hover {
							color: $pink;
						}
					}

					&.active {
						>.nav-link {
							color: $pink;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 20px;
						padding: 0;
						border: none;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 15px;

							.nav-link {
								color: $white;

								&:hover {
									color: $pink;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $pink;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin-left: auto;
				align-items: center;

				>.nav-item {
					margin-left: 15px;

					>.nav-link {
						color: $white;
						font-size: 16px;

						&:hover {
							color: $pink;
						}
					}

					&:last-of-type {
						margin-left: 20px;

						.nav-link {
							padding: 0.2rem 1rem;
							background: $pink;
							border-radius: 6px;
							border: 1px dashed $white;
							box-shadow: 0 0 0 4px $pink;
							color: $white;

							&::after {
								content: "\f0da";
								margin-left: 5px;
								font-weight: 900;
								font-family: "Font Awesome 5 Pro";
							}

							&:hover {
								background-color: $blue-dark;
								box-shadow: 0 0 0 4px $blue-dark;
							}
						}

						&.active {
							.nav-link {
								background-color: $blue-dark;
								box-shadow: 0 0 0 4px $blue-dark;
							}
						}
					}

					&.active {
						>.nav-link {
							color: $pink;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;
						border-radius: 6px;

						.nav-item {
							font-size: 15px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $black;

								&:hover {
									color: $pink;
								}
							}

							&.active {
								.nav-link {
									color: $pink;
								}
							}
						}
					}
				}
			}
		}
	}
}

&.sticky,
&.navbar-collapse-active {
	.header {
		.logo {
			width: 90px;
		}
	}
}

&:not(.home) {
	@include media-breakpoint-down(md) {
		.header {
			.logo {
				width: 90px;
			}
		}
	}
}
