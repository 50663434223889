.page_block {

  &.grid {
    // Video trailer
    .item {
      @include media-breakpoint-up(xl) {
        @include make-col(4);
      }
    }
    &.video-trailer {
		&.inline_video {
			.card {
				padding-left: 0;
				padding-right: 0;
			}
		}

      .item {
        @include media-breakpoint-up(sm) {
          @include make-col(12);
          max-width: 720px;
        }
        .card {
          &.video {
            &:hover {
              .card-image {
                &::after {
                  background-size: 100px;

                }
              }
            }
            .card-body {
              @extend .flex-md-row;
              .card-buttons {
                @extend .m-0;
                a {
                  @extend .btn;
                  @extend .d-block;
                }
              }
            }
          }
        }
      }
    }

    // content blocks
    &.greenblocks {
      margin-top: 80px;

      .item {
        @include media-breakpoint-up(sm) {
          @include make-col(6);
        }
        margin-bottom: 120px;
        .card {
          overflow:visible;
          background: $green;
          border-radius: 6px;
          border: 1px solid #B9B9B9!important;
          .card-image {
            background: none;
            @extend .mx-auto;
          }
          .card-body {
            border-radius: 6px;
            @extend .text-center;
            @extend .border-0;
            background: $green;
            .card-caption {
              .card-description, .card-title {
                @extend .text-center;
              }
              .card-title {
                @extend .tt-card-subtitle;
                @extend .mb-3;
                font-weight: 600;
              }
              .card-description {
                @include media-breakpoint-up(sm) {
                  padding: 0 100px;
                }
                min-height: 100%;
                max-height: 100%;
                &::after {
                  @extend .d-none;
                }
              }
            }
            .card-buttons {
              .card-btn {
                &:hover {
                  border: 1px dashed $white;
                }
              }
            }
          }
        }
      }
    }
  }
}
