&.landing_page_zakelijk,
&.landing_page_consument {
    background-color: #F2EFDA;
    text-align: center;

    background-image: url('/images/background-stipjes.png');
    background-repeat: repeat;
    background-size: cover;
    background-position: center top;
    background-attachment: fixed;
    
    .page-logo {
        img {
            width: 70%;
            margin: auto;
        }
    }

    p {
        @include media-breakpoint-up(lg) {
            font-size: 1.3rem;
        }
    }

    .orange-block {
        @include media-breakpoint-up(lg) {
            font-size: x-large;
        }
    }

    .intro-section {
        @include media-breakpoint-up(lg) {
            &::before {
                content: '';
                background-image: url('/images/ballon-roze.png');
                background-size: contain;
                background-repeat: no-repeat;
                width: 110px;
                height: 290px;
                position: absolute;
                right: -110px;
                top: -260px;
                animation:balloons 4s ease-in-out infinite;
            }
        }
    }

    .block-section.content {

        @include media-breakpoint-up(lg) {
            &::before {
                content: '';
                background-image: url('/images/ballon-grijs.png');
                background-size: contain;
                background-repeat: no-repeat;
                width: 170px;
                height: 340px;
                position: absolute;
                left: -220px;
                bottom: -260px;
                animation:balloons 4s ease-in-out infinite;
            }
        }

        @extend .white-block;
        border-radius: 1rem;
        padding: 0 !important;
        position: relative;

        .container-two-columns {
            .column {
                &.one {
                    padding-left: 15px !important;
                    padding-right: 0 !important;

                    @include media-breakpoint-down(sm) {
                        padding-left: 0 !important;
                    }

                    .owl-carousel {
                        border-top-left-radius: 1rem;
                        border-bottom-left-radius: 1rem;
                        overflow: hidden;
                        @include media-breakpoint-down(sm) {
                            border-bottom-left-radius: 0;
                            border-top-right-radius: 1rem;
                        }    
                    }
                }
                &.two {
                    @include media-breakpoint-up(md) {
                        padding-top: 70px;
                        padding-bottom: 30px;
                        padding-left: 40px !important;
                        padding-right: 55px !important;
                        display: flex;
                        flex-flow: column;
                        justify-content: space-between;
                    }

                    h1, h2, h3, h4, h5, h6 {
                        color: #8C9970;
                        @include media-breakpoint-up(lg) {
                            font-size: xx-large;
                        }
                    }
                }
            }
        }

        .btn.btn-primary {
            background: $blue;
            box-shadow: none;
            border: 1px solid $blue;
            border-radius: 50px;
            font-size: 1.5rem;
            padding: 10px 30px;

            &::after {
                display: none;
            }

            &:hover {
                background: $blue-dark;
                border: 1px solid $blue-dark;
            }
        }

        .title-block {
            h1, h2, h3, h4 {
                position: absolute;
                margin: 0;
                top: -27px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 11;
            }
        }

        .owl-nav {
            display: none;
        }
    }

    .white-block {
        padding-left: 0;
        padding-right: 0;
        border-radius: 1rem !important;
    }

    .hashtag-block {
        padding-left: 0 !important;
        padding-right: 0 !important;

        p {
            @include media-breakpoint-up(sm) {
                padding: 0 60px;
            }
        }
        .page_block.script {
            @include media-breakpoint-down(sm) {
                margin: 0 -15px;
                overflow: hidden;
            }
        }
    }

    .fien-teun {

        @include media-breakpoint-up(lg) {
            &::before {
                content: '';
                background-image: url('/images/ballon-geel.png');
                background-size: contain;
                background-repeat: no-repeat;
                width: 110px;
                height: 290px;
                position: absolute;
                right: -180px;
                top: -160px;
                animation:balloons 4s ease-in-out infinite;
            }
        }

        padding-left: 15px !important;
        padding-right: 15px !important;
        padding-top: 30px;
        padding-bottom: 30px;

        .title-block {
            h2 {
                margin-top: -55px;
            }
        }

        .resource_image {
            @include media-breakpoint-down(sm) {
                max-width: 60%;
                margin: auto;
            }
        }

        .container-three-columns {
            .container-holder {
                .column {
                    @include media-breakpoint-up(lg) {
                        &.one, &.three {
                            flex: 0 0 23%;
                            max-width: 23%;
                            display: flex;
                            align-items: flex-end;
                        }
                        &.two {
                            flex: 0 0 54%;
                            max-width: 54%;
                            padding-left: 0;
                            padding-right: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }

        p {
            @include media-breakpoint-up(lg) {
                line-height: 3.5rem;
            }
        }
    }

    h1,
	h2,
	h3,
	h4,
    h5,
    h6,
    p {
        font-family: "Simplicity", sans-serif;
    }

    h1,
	h2,
	h3,
	h4,
    h5,
    h6,
    b, strong,
    .btn {
        -webkit-text-stroke-color: inherit;
        -webkit-text-stroke-width: 1px;
        letter-spacing: 1px;
    }
}

@keyframes balloons {
    0%,100%{ transform:translateY(0) rotate(-4deg); }
    50%{ transform:translateY(-25px) rotate(4deg); }
}