&.home {
  .intro {
    @extend .pt-4;
    @extend .text-center;
    padding: 50px 0 0;
    .container-holder {
      @include media-breakpoint-up(md) {
        padding: 0 10vw;
      }
    }
  }
  .banner {
    padding: 80px 0 0;
  }
  .videos {
    padding: 0;
  }
  .videos, .news-blocks {
    .container-holder {
      position: relative;
      .btn-more {
        @include media-breakpoint-up(md) {
          position: absolute;
          right: -50px;
          top: 40%;
        }
      }
    }
  }
  .news-blocks {
    padding-bottom: 100px;
    .container-holder {
      .btn-more {
        @include media-breakpoint-up(md) {
          right: -70px;
          top: 36%;
        }
      }
    }
  }

  .content-filled-block {
    padding: 50px 15px;

    .container {
      @extend .card;
      padding: $card-body-padding!important;
      border: 1px solid $primary!important;
      .column {
        &.one {
          @include media-breakpoint-up(sm) {
            @include make-col(4);
            padding-top: 30px;
            padding-bottom: 30px;
          }
        }
        &.two {
          @include media-breakpoint-up(sm) {
            @include make-col(8);
          }
        }
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
  .content-blocks {
	margin: 6vh 0;
	
	.contentblock {
	  .item {
        @include media-breakpoint-up(sm) {
          @include make-col(6);
        }
		.card {
			border-radius: 15px;
			background-color: #fafafa;
			filter: none;
			.card-image {
				background: transparent;
			}
			.card-body {
				background: transparent;
				.card-caption {
					.card-title {
						margin-bottom: 5px;
						color: $blue-dark;
						font-size: 24px;
						&:hover {
							color: $pink;
						}
					}
				}
				.card-buttons {
					.card-btn {
						background-color: $pink;
						box-shadow: 0 0 0 4px $pink;
						font-size: 18px;

						&:hover {
							background-color: $blue;
							box-shadow: 0 0 0 4px $blue;
						}
					}
				}
			}
		}
      }
	}
  }
  .news-blocks {
    @extend .my-5;
    margin-top: -10px!important;
  }
}
