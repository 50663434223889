/* ==========================================================================
   body
   ========================================================================== */
   $body-padding: 0;
   $body-sticky-padding: 0;

/* ==========================================================================
   containers
   ========================================================================== */
   $container-padding: 0 15px;
   $container-padding_sm: 0 15px;

/* ==========================================================================
   content
   ========================================================================== */

   $content-padding: 6vh 0;

/*  content > h1
    ========================================================================== */
    $content-h1-font_size: 1.5rem;
    $content-h1-font_size_sm: 1.5rem;
    $content-h1-color: $headings-color;
    $content-h1-font_family: $font-family-mali;
    $content-h1-margin: 0 0 0 0;

/*  content > h2
    ========================================================================== */
    $content-h2-font_size: 1.4rem;
    $content-h2-font_size_sm: 1.4rem;
    $content-h2-font_weight: 500;


/*  content > h3
    ========================================================================== */
    $content-h3-font_size: 1.3rem;
    $content-h3-font_size_sm: 1.3rem;
    $content-h3-color: $headings-color;
    $content-h3-font_family: $font-family-mali;
    $content-h3-margin: 0 0 0 0;

/*  content > h4
    ========================================================================== */
    $content-h4-font_size: 1.2rem;
    $content-h4-font_size_sm: 1.2rem;

/*  content > h5
    ========================================================================== */
    $content-h5-font_size: 1.1rem;
    $content-h5-font_size_sm: 1.1rem;

/*  content > h6
    ========================================================================== */
    $content-h6-font_size: 1rem; /* 16px */
    $content-h6-font_size_sm: 1rem; /* 16px */


/*  content > ol.custom-list
    ========================================================================== */

    /* content > ol.custom-list > icon */
    $content-ol-custom-list-item-icon: '\f00c';
    $content-ol-custom-list-item-icon-font_size: $font-size-base;
    $content-ol-custom-list-item-icon-font_weight: $font-weight-bold;

/* ==========================================================================
    card
    ========================================================================== */

   $card-box_shadow: drop-shadow(0 3px 6px rgba($black, 0.25));
   $card-body-padding: 20px;

/* ==========================================================================
   owl-carousel
   ========================================================================== */
   $carousel-min_height: 50vh !default;
