/*  bootstrap > colors
    ========================================================================== */
$white: #ffffff;
$black: #000000;
$pink: #f1538f;
$blue: #0cb8e5;
$green: #94c349;
$orange: #FFE1BF;
$blue-light: #d6edf6;
$blue-dark: #1f66b3;
$orange-dark: #FFA350;
$green-dark: #8C9970;
$beige: #f1e8c8;
$green-new: #98C896;
/*  bootstrap > theme-colors
    ========================================================================== */
$primary: $blue;
$secondary: $pink;

$dark: $beige;

/*  bootstrap > body
    ========================================================================== */
$body-bg: $blue-light;
$body-color: $black;

$link-color: $primary;
$link-hover-decoration: none;

/*  bootstrap > font
    ========================================================================== */
@import url("https://fonts.googleapis.com/css2?family=Mali:wght@300;400;500;600;700&family=Quicksand:wght@300;400;500&display=swap");

$font-family-mali: "Mali", sans-serif !default;
$font-family-quicksand: "Quicksand", sans-serif !default;
$font-family-base: $font-family-quicksand !default;

/*  bootstrap > typography
    ========================================================================== */
$font-weight-light: 300 !default;
$font-weight-medium: 400 !default;
$font-weight-bold: 500 !default;

$font-style-italic: italic !default;
$font-style-base: 400 !default;

$headings-font-family: $font-family-mali !default;
$headings-font-style: $font-style-base !default;
$headings-color: $primary !default;

/*  misc
    ========================================================================== */

/* transition */
a,
.btn,
.owl-btn,
.card-btn {
    transition: 0.5s !important;
}

body.default {
    overflow-x: hidden;
}
