&.one_column_page {
  .white-block {
    .container-holder {
      max-width: 620px;
      @extend .mx-auto;
      @extend .text-center;
    }
  }

  .videos {
    .card-buttons {
      @extend .d-none;
    }
  }
  .banner {
    padding-top: 0;
    padding-bottom: 80px;
    .owl-carousel {
      .item {
        .owl-caption {
          @include media-breakpoint-up(lg) {
            margin-right: 30px!important;
          }
        }
      }
    }
  }
  .videos, .news-blocks {
    padding-bottom: 100px;
    .container-holder {
      position: relative;
      .btn-more {
        @include media-breakpoint-up(md) {
          position: absolute;
          right: -50px;
          top: 36%;
        }
      }
    }
  }
}
