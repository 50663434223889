.footer {
	position: relative;
	margin-top: calc(6vh + 70px);
	background-color: $green;

	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 100%;
		height: 70px;
		background: url('/images/footer-grass.png') repeat-x;
	}

	// footer-outro
	.footer-outro {
		padding: 30px 0 6vh 0;
		background-color: $green;

		.container-holder {
			.column {

				&.one,
				&.two {
					@include media-breakpoint-down(sm) {
						@include make-col(6);
					}
				}

				&.three {
					@include media-breakpoint-down(sm) {
						@include make-col(12);
						margin-top: 30px;
					}
				}
			}
		}

		// footer_text
		.footer_text {
			h5 {
				color: $white;
				font-size: 30px;
			}

			h6 {
				color: $white;
				font-size: 20px;
				text-align: center;
			}

			ul {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					margin-top: 5px;
				}
			}

			a {
				color: $black;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		// social-icons
		.social-icons {
			margin-top: 30px;
			justify-content: center;

			li {
				margin: 5px;

				a {
					display: block;
					width: 60px;
					height: 60px;
					background-color: $pink;
					border-radius: 100%;
					color: $white;
					font-size: 30px;
					line-height: 60px;
					text-align: center;

					span {
						display: none;
					}

					&:hover {
						background-color: $blue;
					}
				}
			}
		}

	}

	// footer-copyright
	.footer-copyright {
		padding: 20px 0;
		background-color: $white;
		font-size: 15px;

		@include media-breakpoint-down(sm) {
			text-align: center;
		}

		.container-holder {
			align-items: center;
			justify-content: center;

			@include media-breakpoint-up(md) {
				justify-content: space-between;

				.column {
					flex: 0 0 auto;
					width: auto;
					max-width: 100%;
				}
			}
		}

		// footer_link
		.footer_link {
			ul {
				@include media-breakpoint-down(lg) {
					flex-direction: column;
				}

				li {
					margin-right: 0;

					@include media-breakpoint-up(xl) {
						+li {
							&::before {
								content: "|";
								margin: 0 10px;
							}
						}
					}

					a {
						color: $black;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}

		// footer_text
		.footer_text {
			p {
				margin-bottom: 0;
			}
		}
	}
}
