@font-face {
    font-family: 'Simplicity';
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    src: url('./Simplicity.eot');
    src: url('./Simplicity.eot?#iefix') format('embedded-opentype'),
        url('./Simplicity.woff2') format('woff2'),
        url('./Simplicity.woff') format('woff'),
        url('./Simplicity.ttf') format('truetype');
}
