.bg-dark {
	//background-image: url('~project/public/images/ornament-light.png');
	// background-color: $beige;
	position: relative;
}

.white-block {
	@extend .mx-auto;
	@extend .card;
	@extend .px-5;
	padding-top: 60px;
	padding-bottom: 60px;

	@include media-breakpoint-down(xs) {
		margin-left: 15px !important;
		margin-right: 15px !important;
	}

	margin-top: 80px;
	margin-bottom: 80px;
	max-width: 1140px;
	@include media-breakpoint-down(lg) {
		max-width: 960px;
	}
	@include media-breakpoint-down(md) {
		max-width: 720px;
	}
	@include media-breakpoint-down(sm) {
		max-width: 540px;
	}
	border: 1px solid $primary;
	overflow: visible;
	.floating-top-image {
		max-width: 200px;
		position: absolute;
		top: -60px;
		left: 30px;
		@include media-breakpoint-down(md) {
			max-width: 100px;
			top: -15px;
			left: -15px;
		}
	}
}

.orange-block {
	@extend .white-block;
	background-color: $orange;
	border-radius: 1rem !important;

	h1, h2, h3, h4, h5, h6 {
		color: $orange-dark;
	}
}

.green-block {
	@extend .white-block;
	background-color: $green-new;
	border-radius: 1rem !important;

	h1, h2, h3, h4, h5, h6 {
		color: $white;
	}
}

.video-block {
	padding-bottom: 150px;
	.container-default {
		.container-holder {
			max-width: 720px;
			@extend .mx-auto;
			@extend .text-center;
		}
	}
}
