.polaroid {
  margin-top: 100px;
  @include media-breakpoint-down(md) {
    margin-top: 0;
  }
  &.container-default {
    .container-holder {
      max-width: 820px!important;
    }
  }
  .gallery {
    margin-bottom: -300px;
    max-width: 100%;
    @extend .mx-auto;
    transform: rotate(3deg);
    padding-top: 30px;
    .gallery-item {
      @extend .d-none;
      &.large-item {
        @extend .d-block;
        background: transparent url('~project/public/images/gallery-border.png') center center no-repeat;
        background-size: cover;

        .gallery-link {
          @extend .p-0;
        }
        img {
          transform: rotate(3deg);
          padding: 55px 65px 70px 50px;
          @include media-breakpoint-down(sm) {
            padding: 40px 40px 40px 30px;
          }
        }
      }
    }
  }
  &::after {
    content: '';
    margin-bottom: 320px;
  }
}
