// body clouds bg
&:not(.bg-custom) {
	background-image: url('/images/body-clouds-bg.png');
	background-size: 1581px 1916px;
	background-repeat: repeat;
	background-color: $blue-light;
	background-position: center top;
	background-attachment: fixed;

	@include media-breakpoint-down(lg) {
		background-size: 100%;
	}
}

// body custom bg
&.bg-custom {
	background-color: $white;
	background-attachment: fixed;
	background-position: center top;

	@include media-breakpoint-up(md) {
		background-size: cover;
	}
}

// scroll-to-top
a.scroll-to-top {
	@include media-breakpoint-down(lg) {
		display: none;
	}

	position: fixed;
	top: 50%;
	right: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 110px;
	height: 180px;
	border-radius: 15px;
	border: 2px solid $blue-dark;
	background-color: $blue-light;
	color: $blue-dark;
	font-size: 15px;
	font-weight: 500;
	text-align: center;

	&::before {
		content: "\f0d8";
		order: -2;
		display: block;
		font-size: 18px;
		font-weight: 900;
		font-family: "Font Awesome 5 Pro";
	}

	&::after {
		content: "";
		order: -1;
		display: block;
		width: 72px;
		height: 67px;
		background: url('/images/butterfly.png');
	}

	&:hover {
		margin-top: -5px;
	}
}

&:not(.windowScrolled) {
	a.scroll-to-top {
		display: none !important;
	}
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

