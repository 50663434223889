.title-block {
	h1,
	h2,
	h3,
	h4 {
		width: 100%;
		max-width: 340px;
		min-height: 54px;
		background: $secondary;
		border-radius: 0;
		border: 1px dashed $white;
		box-shadow: 0 0 0 4px $secondary;
		color: $white;
		margin-top: -85px;
		margin-bottom: 50px;
		font-size: 1.5rem;
		@extend .mx-auto;
		@extend .d-flex;
		@extend .align-items-center;
		@extend .text-center;
		@extend .justify-content-center;
		@include media-breakpoint-down(md) {
			max-width: 260px;
			font-size: 1.25rem;
		}
	}

	&.orange-title {
		h1, h2, h3, h4 {
			background: $orange-dark;
			box-shadow: 0 0 0 4px $orange-dark;
		}
	}

	&.blue-title {
		h1, h2, h3, h4 {
			background: $blue;
			box-shadow: 0 0 0 4px $blue;
		}
	}


	&.dark-blue-title {
		h1, h2, h3, h4 {
			background: $blue-dark;
			box-shadow: 0 0 0 4px $blue-dark;
		}
	}

	&.dark-green-title {
		h1, h2, h3, h4 {
			background: $green-dark;
			box-shadow: 0 0 0 4px $green-dark;
		}
	}
}
