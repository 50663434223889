&.landing_page_winterdorp {

  .header {
    .header-main {
      .logo { max-width: 325px;}
    }
  }

  .content-blocks {
    padding: 0;
    .card {
      .card-body {
        .card-buttons {
          .card-btn {
            display: block;
          }
        }
      }
    }
  }
  .white-block {
    .container-default {
      .container-holder {
        h1 {
          text-align: center;
          width: 100%;
          max-width: 340px;
          min-height: 54px;
          background: #951639;
          border-radius: 0;
          border: 1px dashed $white;
          box-shadow: 0 0 0 4px #951639;
          color: $white;
          margin-top: -85px;
          margin-bottom: 50px;
          font-size: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .videos {
    padding: 0;
  }

}
