&.blog_post_overview {
  .content {
    padding-bottom: 100px;
  }
}

&.blog_post_detail {
  .content {
    &.white-block {
      margin-bottom: 150px;
    }
  }
  .blog_detail {
    @extend .text-center;
    @extend .mx-auto;
    .info {
      padding: 0!important;
      @include media-breakpoint-up(lg) {
				@include make-col(12);
			}
      .btn-back {
        position: absolute;
        top: -50px;
      }
      .extra {
        @extend .tt-card-title;
      }
      h1 {
        @extend .tt-card-subtitle;
        @extend .mb-4;
      }
    }
    .info-extra {
			@include media-breakpoint-up(lg) {
				@include make-col(12);
			}
		}
  }
}



