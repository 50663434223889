&.two_column_page {
 
  .white-block {
    .container-default {
      .container-holder {

        max-width: 620px;
        @extend .mx-auto;
        @extend .text-center;
        margin-bottom: 20px;
        
      }
    }
    padding-bottom: 40px;
  }
  .videos, .news-blocks {
    padding-bottom: 100px;
    .container-holder {
      position: relative;
      .btn-more {
        @include media-breakpoint-up(md) {
          position: absolute;
          right: -50px;
          top: 36%;
        }
      }
    }
  }
}