/* intro - typography */
.tt-intro-title {
  font-size: $font-size-base * 1.875;
  font-weight: 500;
  @extend .text-uppercase;
  color: $blue;
}
.tt-intro-subtitle {
  font-size: $font-size-base * 1.875;
  font-weight: 500;
  @extend .text-uppercase;
  color: $orange;
}
.tt-intro-text {
  font-size: $font-size-base * 0.9375;
  font-weight: 500;
  color: $blue;
}

// menu 
.tt-menu-main {
  font-size: 1rem;
  font-family: $font-family-mali;
}

// banner
.tt-banner-caption-title {
  font-size: 1.5rem;
  color: $black;
}
.tt-banner-caption-subtitle {
  font-size: 1rem;
  color: $black;
}

/* content - typography */
.tt-content-title {
  font-size: $font-size-base * 1.625;
  font-weight: 500;
  @extend .text-uppercase;
  color: $blue;
}
.tt-content-title-white {
  @extend .tt-content-title;
  color: $white;
}

.tt-content-text {
  font-size: $font-size-base;
  font-weight: 300;
  color: $black;
}
.tt-content-text-white {
  @extend .tt-content-text;
  color: $white;
}

.tt-content-subtitle {
  font-size: $font-size-base;
  font-weight: 300;
  @extend .text-uppercase;
  color: $orange;
}
.tt-content-subtitle-white {
  @extend .tt-content-subtitle;
  color: $white;
}

/* card - typography */
.tt-card-title {
  font-size: $font-size-base;
  font-family: $font-family-mali;
  color: $primary;
}

.tt-card-subtitle {
  font-size: $content-h2-font_size;
  font-family: $font-family-base;
  color: $black;
}

.tt-card-text {
  font-size: $font-size-base;
  font-weight: 300;
  color: $black;
}

.tt-footer-text {
  font-size: $font-size-base;
  font-weight: 300;
  color: $white;
}