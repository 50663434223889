.fancybox-container {
  .fancybox-bg {
    background-color: #d6edf6;
    background-image: url('~project/public/images/bg-clouds.png');
    background-position: 0 0;
	background-size: contain;
    background-repeat: no-repeat;
    opacity: 1;
  }
  .fancybox-stage {
    .fancybox-slide {
      padding: 8vw;
      filter: $card-box_shadow;
      .fancybox-content {
        border: 2px solid $white;
      }
    }

  }
  .fancybox-toolbar {
    .fancybox-button--close {
      border-radius: 100%;
      background: $primary;
      color: $white;
      width: 75px;
      height: 75px;
      padding: 15px;
      right: 50vw;
      top: 10vh;
      margin-right: -38px;
      filter: $card-box_shadow;
    }
  }
}
