&.landing_page_zakelijk {
    .block-section.content {

        .container {
            @include media-breakpoint-down(sm) {
                padding: 0;
            }
        }

        .owl-carousel {
            border-radius: 1rem;
            overflow: hidden;
            .owl-nav {
                display: block;
            }
        }

        &:has(.owl-carousel) {
            h3 {
                margin: 1rem 0;
                color: $green-new;
            }
            h3,
            p {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }

    .page_block.grid {
        .item {
            flex: 0 0 100%;
            max-width: 100%;

            .card {
                border-radius: 1rem !important;

                &.video:hover {
                    .card-image::after {
                        background-size: 135px;
                    }
                }

                .card-title {
                    font-size: 1.5rem;
                }
            }
        }
    }

    .content-section {
        position: relative;
        @include media-breakpoint-up(lg) {
            &::before {
                content: '';
                background-image: url('/images/ballon-geel.png');
                background-size: contain;
                background-repeat: no-repeat;
                width: 110px;
                height: 290px;
                position: absolute;
                right: 120px;
                top: 0;
                animation:balloons 4s ease-in-out infinite;
            }
        }

        .title-block {
            h1, h2, h3, h4 {
                margin-top: 0;
            }
        }

        .page_block.grid {
            .item {

                @include media-breakpoint-up(sm) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                .card {
                    .card-title {
                        color: $green-dark;
                        text-align: center;
                        margin-bottom: 1rem;
                    }
                    .card-subtitle {
                        display: none;
                    }

                    .card-description-content {
                        p {
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;

                        }
                    }
                    .card-buttons {
                        display: none;
                    }
                }
            }
        }

        
    }

    .contact-section {

        .title-block {
            h1, h2, h3, h4 {
                max-width: max-content;
                padding: 0 1rem;
            }
        }

        h3 {
            color: $green-dark;
        }

        .container-four-columns {
            .container-holder {
                align-items: flex-end;

                @include media-breakpoint-down(sm) {
                    .column {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }
            }
        }

        .btn.btn-primary {
            background: $blue;
            box-shadow: none;
            border: 1px solid $blue;
            border-radius: 50px;
            font-size: 1.5rem;
            padding: 10px 30px;

            &::after {
                display: none;
            }

            &:hover {
                background: $blue-dark;
                border: 1px solid $blue-dark;
            }
        }
    }
}