.banner {
  position: relative;
	.eyecatcher-container {
	}

	&.large {
    .container-default {
      padding: 0;
    }
		.item {
			.owl-caption {
				width: 435px;
        height: 150px;
        @extend .mx-auto;
        @extend .text-center;
        @extend .py-4;
        @extend .px-5;
        background: transparent url('~project/public/images/bg-caption-text.png') 0 0 no-repeat;
        > * {
          text-shadow: none;
        }
        .owl-title {
          @extend .tt-banner-caption-title;
        }
        .owl-subtitle {
          @extend .tt-banner-caption-subtitle;
          height: 55px;
          overflow: hidden;
        }
        .owl-btn {
          box-shadow: 0 0 0 4px $blue-dark!important;
          &:hover {
            box-shadow: 0 0 0 4px darken($secondary, 20%)!important;
            border-color: $white!important;
          }
        }
			}
		}
	}
  .image-floating {
    position: absolute;
    bottom: 0;
    z-index: 10;
    // tijdelijk uit
    display: none;
  }
  @media (prefers-reduced-motion: no-preference) {
    .image-floating {
      .img-fluid {
        transition: all 1.5s ease;
        opacity: 1;
        //margin-bottom: -200px;
      }
    }
  }

  .square-transition {
    animation: wipe-enter 3s;
    animation-direction: alternate; /* or: normal */
    animation-timing-function: ease-out; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
    animation-fill-mode: forwards; /* or: backwards, both, none */
    animation-iteration-count: infinite;
  }

  @keyframes wipe-enter {
    0% {
      margin-bottom: -20px;
    }
    80% {
      margin-bottom: 0px;
    }
  }
}
