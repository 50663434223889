@import './7_fonts/fonts';
@import './0_config/vendor-variables';
@import '~framework/assets/scss/Dev/0_config/vendor-variables';
@import '~framework/assets/scss/Dev/setup';
@import './0_config/website-variables';
@import '~framework/assets/scss/Dev/0_config/website-variables';

@import '~framework/assets/scss/Dev/1_vendor/bootstrap';

body {
	@import '~framework/assets/scss/Dev/base';

	// @import './2_layout/header';
	// @import './2_layout/eyecatcher';
	@import './2_layout/typography';
	@import './2_layout/base';
	@import './2_layout/content';
	@import './2_layout/footer';
	@import './2_layout/header';
	@import './2_layout/banner';
	@import './2_layout/sections';

	@import './3_components/cards';
	// @import './3_components/owl-carousel';
	@import './3_components/buttons';
	@import './3_components/fancybox';

	@import './4_page_block/collections';
	@import './4_page_block/gallery';
	@import './4_page_block/title_block';

	@import './6_theme/page.home';
	@import './6_theme/one_column_page';
	@import './6_theme/two_column_page';
	@import './6_theme/three_column_page';
	@import './6_theme/landingspage';
	@import './6_theme/landingspage_stipdepony';
	@import './6_theme/landingspage_mikeenmolly';
	@import './6_theme/landingspage_winterdorp';
	@import './6_theme/landingpage_consument';
	@import './6_theme/landingpage_zakelijk';
	@import './6_theme/passepartout';
	@import './6_theme/module.blog';

	// @import './6_theme/module.all';
	// @import './6_theme/module.accommodations';
	// @import './6_theme/module.assortiment';
	// @import './6_theme/module.services';
	// @import './6_theme/page.landingspage';
}
