
.btn {
  max-width: 100%;
  @extend .btn-base;
}

/* btn-base
   ========================================================================== */
   .btn-base {
	display: inline-flex;
	align-items: center;
    min-height: 32px;
    background: $blue-dark;
    border-radius: 6px;
    border: 1px dashed $white;
    box-shadow:  0 0 0 4px $blue-dark;
    color: $white;
    margin: 10px;

    &:hover {
      background-color: darken($blue-dark, 15%);
      box-shadow:  0 0 0 4px darken($blue-dark, 15%);
      border: 1px dashed $white;
      color: $white;
    }

	&::after {
        content: "\f0da";
		margin-left: 10px;
		font-weight: 900;
		font-family: "Font Awesome 5 Pro";
	}
  }

/* btn-menu
   ========================================================================== */
   .btn-menu {
      background: $primary;
      box-shadow:  0 0 0 4px $primary;
      &::before {
        font-family: "Font Awesome 5 Pro";
        font-weight: 700;
        content: "\f0c9";
      }
   }

/* btn-more
   ========================================================================== */
    .btn-more {
      @extend .d-flex;
      @extend .flex-column;
      @extend .align-items-center;
      @extend .justify-content-center;
      @extend .text-center;
      @include media-breakpoint-up(md) {
        width: 100px;
        height: 100px;
        background: $secondary;
        border-radius: 100px;
        margin: 0 10px;
        line-height: 1.2rem;
        border: none;
        color: $white;
        box-shadow: 0 0 0 5px $secondary!important;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='white' stroke-width='2' stroke-dasharray='5%25%2c 4%25' stroke-dashoffset='86' stroke-linecap='butt'/%3e%3c/svg%3e");
        border-radius: 100px;
      }
        &:hover {
          background-color: darken($secondary, 5%);
        }
        &::after {
          @include media-breakpoint-up(md) {
            font-family: "Font Awesome 5 Pro";
            font-weight: 700;
            content: "\f061";
            color: $secondary;
            position: absolute;
            font-size: 24px;
            right: -25px;
          }
        }
    }

/* btn-white
   ========================================================================== */
.btn-white {
  background: $white;
  border: 1px solid $white;
  color: $body-color;

  &:hover {
    background: $primary;
    border: 1px solid $primary;
  }
}

/* btn-link
   ========================================================================== */
.btn-link {
  &--primary {
    color: $primary;

    &:hover {
      color: $secondary;
    }
  }

  &--white {
    color: $white;

    &:hover {
      color: $primary;
    }
  }
}

.btn-primary {
  &:hover {
    background: darken($primary, 15);
  }
}
.btn-secondary {
  &:hover {
    background: darken($secondary, 25);
  }
}

/* btn-back
   ========================================================================== */
.btn-back {
  @extend .mb-5;
  @extend .btn-link--primary;
  @extend .add-arrow--before;
  @extend .add-arrow--before-r;
  @extend .d-block;

  transition: $transition-base;
}
