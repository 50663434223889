// lead-section
// =========================================================================
.lead-section {
	position: relative;
	background-image: url('/images/header-clouds-visual.png');
	background-color: #a4e2f9;
	background-size: 1615px 600px;
	background-position-y: 0px;
	background-position-x: -1615px;
	background-repeat: repeat-x;
	border-bottom: 2px solid $green;
	animation: movingClouds 120s linear;

	@include media-breakpoint-down(lg) {
		background-size: 100%;
		background-position-y: 0;
		background-position-x: -100vw;
	}

	@keyframes movingClouds {
		50% {
			background-position-x: 0;
		}
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 600px;
		//background: url('/images/grass.png') repeat-x;
		background: url('/images/header-bg-visual.png') no-repeat center center;
	}

	.container-one-column {
		.container-holder {
			max-width: 500px;
			margin: 0 auto;
			text-align: center;
			.column {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				height: 600px;
			}
		}
	}

	h1 {
		color: $pink;

		@include media-breakpoint-up(xl) {
			font-size: 60px;
		}
	}

	.visual-fien-en-teun {
		z-index: 10;
		position: relative;
		margin-top: 30px;

		&::before {
			content: "";
			position: absolute;
			bottom: 90%;
			right: -109px;
			width: 129px;
			height: 109px;
			background: url('/images/butterfly-moving.png');
			animation: butterfly 5s infinite alternate;

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
	}

	@keyframes butterfly {
		0% {
			bottom: 80%;
		}

		100% {
			bottom: 90%;
		}
	}
}

// book-section
// =========================================================================
.book-section {
	margin: 6vh 0;
	text-align: center;

	.container {
		.container-holder {
			align-items: center;
			margin-left: 0;
			margin-right: 0;
			border-bottom: 1px solid $blue-dark;
			padding-bottom: 30px;
		}
	}

	h2 {
		color: $blue-dark;

		@include media-breakpoint-up(xl) {
			font-size: 40px;
		}
	}

	.btn {
		display: inline-flex;
		align-items: center;
		background-color: $blue;
		box-shadow: 0 0 0 4px $blue;
		font-size: 20px;

		&:hover {
			background-color: $pink;
			box-shadow: 0 0 0 4px $pink;
		}

		&::after {
			content: "\f0da";
			margin-left: 10px;
			font-weight: 900;
			font-family: "Font Awesome 5 Pro";
		}
	}
}

// media-section
// =========================================================================
.media-section {
	margin: 6vh 0;

	.container {
		.container-holder {
			.column {
				display: flex;
				flex-flow: column nowrap;

				&.one {
					padding-right: 15px !important;
				}

				&.two {
					padding-left: 15px !important;
				}
			}
		}
	}

	.media-group-holder {
		height: 100%;
		margin: 15px 15px 45px 15px;
		padding: 15px;
		background-color: $blue;
		box-shadow: 0 0 0 15px $blue;
		border: 2px dashed $white;

		.heading {
			margin-bottom: 30px;
			color: $white;
			font-size: 30px;
			text-align: center;
		}

		.grid {
			margin-bottom: 0;

			.item {
				@include make-col(12);

				.card {
					align-items: flex-start;
					padding-bottom: 30px;
					background: transparent;
					border-radius: 0;
					border-bottom: 1px solid rgba($white, 0.5) !important;
					overflow: unset;
					filter: none;

					.card-image {
						@include make-col(4);
						background: transparent;
					}

					.card-body {
						@include make-col(8);
						padding-top: 0;
						padding-right: 0;
						padding-bottom: 0;
						background: transparent;

						.card-caption {
							.card-title {
								margin-bottom: 5px;
								color: $black;
								font-size: 24px;
								font-weight: 400;

								&:hover {
									color: $pink;
								}
							}
						}
					}

					&.video {
						.card-image {
							border: 6px solid $white;
							transform: rotate(-5deg);
							box-shadow: 0 3px 6px rgba($black, 0.15);
						}
					}
				}
			}
		}

		.link {
			@extend .btn;
			background-color: $pink !important;
			box-shadow: 0 0 0 4px $pink !important;
			font-size: 18px;

			&:hover {
				background-color: $blue-dark !important;
				box-shadow: 0 0 0 4px $blue-dark !important;
			}
		}
	}
}
