&.landing_page2 {
  .main {
  
  }

  .passepartout {
    // temp
    display: none !important;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    .floating-img {
      position: fixed;
      overflow: hidden;
      &.fi-leftcenter {
        top: 200px;
        left: 0;
        width: 325px;
        height: 284px;
        @extend .d-flex;
        @extend .align-items-center;
        @include media-breakpoint-down(lg) {
          width: 20vw;
        }
      }
      &.fi-leftbottom {
        bottom: 0;
        left: 0;
        width: 325px;
        height: 284px;
        @extend .d-flex;
        @extend .align-items-end;
        @include media-breakpoint-down(lg) {
          width: 20vw;
        }
      }

      &.fi-leftbottommiddle {
        bottom: 0;
        left: 325px;
        width: 200px;
        height: 120px;
        @extend .d-flex;
        @extend .align-items-end;
        @include media-breakpoint-down(lg) {
          width: 12vw;
          left: 20vw;
        }
      }
      &.fi-rightcenter {
        top: 200px;
        right: 0;
        width: 325px;
        height: 284px;
        @extend .d-flex;
        @extend .align-items-center;
        @include media-breakpoint-down(lg) {
          width: 20vw;
        }
      }
      &.fi-rightbottom {
        bottom: 0;
        right: 0;
        width: 325px;
        height: 284px;
        @extend .d-flex;
        @extend .align-items-end;
        @include media-breakpoint-down(lg) {
          width: 20vw;
        }
      }

      &.fi-rightbottommiddle {
        bottom: 0;
        right: 325px;
        width: 200px;
        height: 120px;
        @extend .d-flex;
        @extend .align-items-end;
        @include media-breakpoint-down(lg) {
          width: 12vw;
          right: 20vw;
        }
      }
      
    }

  }

  .header {
  
    .header-main {
  
      // floating images
      .floating-image-left, .floating-image-right {
        // temp
        display: none;
        position: fixed;
        width: 460px;
        height: 200px;
        animation: none;
        max-width: inherit;
        overflow: hidden;
        @include media-breakpoint-down(md) {
          width: 25vw;
        }
      }
      .floating-image-left {
        left: 0!important;
        margin-top: 0!important;
        top: 0;
      }
      .floating-image-right {
        right: 0!important;
        margin-top: 0!important;
        top: 0;
        
      }
    }
  }
 
  .white-block {
    padding-bottom: 0 !important;
    @include media-breakpoint-up(xl) {
      max-width: 1140px !important;
  }
    max-width: 720px;
    .container-default {
      max-width: 720px;
      .container-holder {

        max-width: 620px;
        @extend .mx-auto;
        @extend .text-center;
        margin-bottom: 50px;
      }
    }
  }
  .block-section {
    @extend .white-block;
    overflow: hidden;
    background: transparent !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
    margin: 40px 0 0;
    @include media-breakpoint-up(md) {
      flex: 0 0 70% !important;
      max-width: 70% !important;
      }
    @include media-breakpoint-up(xl) {
    flex: 0 0 33.33% !important;
    max-width: 33.33% !important;
    }

    .container-two-columns {
      @include make-col(12);
      background: $white;

      .column.one {
        @include make-col(12);
      }
      .column.two {
        @include make-col(12);
     }
      .column {
        padding: 15px 30px!important;
        .gallery {
          margin: 0 !important;
          .gallery-item {
            margin: 0;
            padding: 0;
            &:hover {
              transform: none;
            }
          }
        }
      }
    }
    &.block-left {
      .container-two-columns {
        .container-holder {
          .column.one {
            order: 2;
          }
        }
      }
    }
    // &-block-right {
    //   .container-two-columns {
    //     .container-holder {
    //       .column.one {
    //         order: 1;
    //       }
    //     }
    //   }
    // }
  }
  // .block-section {
  //   @extend .white-block;
  //   padding: 0!important;
  //   &.block-left {
  //     .container-two-columns {
  //       .column.one {
  //         @extend .p-5;
  //       }
  //       .column.two {
  //         @extend .pl-0;
  //       }
  //       .owl-carousel {
  //         min-height: 320px;
  //         height: 320px;
  //         border-bottom-right-radius: 6px!important;
  //         border-top-right-radius: 6px!important;
  //         overflow: hidden;
  //       }
  //     }
  //   }
  //   &.block-right {
  //     .container-two-columns {
  //       .column.one {
  //         @extend .pr-0;
  //       }
  //       .column.two {
  //         @extend .p-5;
  //         @extend .pl-5;
          
  //       }
  //       .owl-carousel {
  //         min-height: 320px;
  //         height: 320px;
  //         border-bottom-left-radius: 6px!important;
  //         border-top-left-radius: 6px!important;
  //         overflow: hidden;
  //       }
  //     }
  //   }
  // }
  .content {
    position: relative;
    &.gallery {
      @extend .pt-0;
      @extend .m-0;
     
      .container-default {
        &.polaroid {
          max-width: 820px;
          @extend .mt-0;
          .container-holder {
            max-width: 820px;
            .gallery {
              @extend .m-0;
            }
          }
        }
      }
    }
  }
  
 /* ==========================================================================
   Footer
   ========================================================================== */
   .footer {
    position: relative;
    background:none;
    .footer-top {
      padding-top: 100px;
      .container-default {
        @include media-breakpoint-up(lg) {
          max-width:720px;
        }
        @include media-breakpoint-up(xl) {
          max-width: 1140px;
        }
      }
      &::before {
        @extend .d-none;
      }
      .container-default{
        @extend .card;
        
        padding: 50px!important;
        background: white;
        overflow: visible;
        .container-holder {
          @include make-row();
          .footer-text {
            @include make-col-ready();
            @include make-col(12);

            @include media-breakpoint-up(lg) {
              @include make-col(8);
            }
          }
          .footer-ft {
            /*
            @include make-col-ready();
            @include make-col(12);

            @include media-breakpoint-up(lg) {
              @include make-col(4);
            }
            position: relative;
            right: inherit;
            top: inherit;
            @include media-breakpoint-down(md) {
              max-width: 150px;
            }
            */
          }
        }
      }
    }
    &::after {
      @extend .d-none;
    }
   }

}

